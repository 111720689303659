import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const SaveTimeImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "network/supercharge/img/supercharge-uk.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1240) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.fileName.childImageSharp.fluid}
			alt="Supercharge uk"
		/>
	)
}

export default SaveTimeImage
