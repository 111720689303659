// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import SaveTimeImage from './img'
import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { isUSA } from 'shared/get-country'

const getWordingWidth = screenWidth => {
	if (screenWidth > breakpoints.laptop) {
		return '485px'
	} else if (screenWidth >= breakpoints.tablet) {
		return '636px'
	} else if (screenWidth > breakpoints.mobile) {
		return '460px'
	}
	return '636px'
}

const SaveTime = ({ screenWidth, country }) => {
	const personali = isUSA(country) ? 'personalized' : 'personalised'
	return (
		<Div
			css={{
				[tablet]: { margin: '0 auto', maxWidth: getWordingWidth(screenWidth) },
			}}
		>
			<InfoAndImage
				screenWidth={screenWidth}
				image={<SaveTimeImage country={country} />}
				stackedImageOnTop
				spacerWidths={['40px', '30px']}
				subtitle={
					<Div
						css={{
							[laptop]: { fontSize: '38px', maxWidth: '460px' },
							[tablet]: { maxWidth: '635px' },
							[mobile]: { fontSize: '32px' },
						}}
					>
						{`Save time with ${personali} search results from BuildingConnected Pro.`}
					</Div>
				}
				info={
					<Div
						css={{
							[laptop]: { fontSize: '22px', maxWidth: '460px' },
							[tablet]: { maxWidth: '478px' },
							[mobile]: { fontSize: '20px', maxWidth: '290px' },
						}}
					>
						{`The more you use it, the better it gets. BuildingConnected learns
						your preferences to show you ${personali}, relevant search results.`}
					</Div>
				}
				centered={screenWidth < breakpoints.laptop}
				wordingWidth={getWordingWidth(screenWidth)}
				bulletItems={[
					'Preferred company',
					'Preferred contact',
					'Flag and hide subcontractors',
					'Do-not-use list',
				]}
			/>
		</Div>
	)
}

export default SaveTime
