// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, mobile } from 'shared/media-queries'
import DiscoverImage from './img'

const getWordingWidth = screenWidth => {
	if (screenWidth > breakpoints.laptop) {
		return '485px'
	} else if (screenWidth > breakpoints.mobile) {
		return '500px'
	} else if (screenWidth <= breakpoints.mobile) {
		return '315px'
	}
	return '470px'
}

const Discover = ({ screenWidth, bulletItems, country }) => (
	<Div>
		<InfoAndImage
			screenWidth={screenWidth}
			image={<DiscoverImage screenWidth={screenWidth} country={country} />}
			imageOnRight
			stackedImageOnTop
			spacerWidths={['35px', '10px']}
			subtitle={
				<Div
					css={{
						[laptop]: { fontSize: '38px', maxWidth: '460px' },
						[mobile]: { fontSize: '32px' },
					}}
				>
					Discover the right subcontractor for any project.
				</Div>
			}
			info={
				<Div
					css={{
						[laptop]: { fontSize: '22px', maxWidth: '478px' },
						[mobile]: { fontSize: '20px', maxWidth: '315px' },
					}}
				>
					Looking for subcontractors in a new location or unfamiliar trade?
					Powerful search filters put you in control to find the right
					subcontractor for every project.
				</Div>
			}
			centered={screenWidth < breakpoints.laptop}
			wordingWidth={getWordingWidth(screenWidth)}
			bulletItems={bulletItems}
		/>
	</Div>
)

export default Discover
