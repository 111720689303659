import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

const Div = styled.div`
	width: 100%;
`
const SaveTimeImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "network/save-time/img/image-uk.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1240) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<Div>
			<GatsbyImage
				fluid={data.fileName.childImageSharp.fluid}
				alt="More effective and efficient file management uk"
			/>
		</Div>
	)
}

export default SaveTimeImage
