// @flow
import * as React from 'react'
import { Testimonials } from 'views/components'
import { BrianImage, KyleImage, NickImage } from './img'

const Quotes = quotes => (
	<Testimonials
		header="What customers are saying about us."
		data={[
			{
				image: <BrianImage />,
				quote:
					'One of my favorite features is the ability to quickly search and locate valid subcontractors.',
				name: 'Brian Goodwin',
				title: 'Director of Preconstruction at',
				company: 'Ryan Companies',
			},
			{
				image: <KyleImage />,
				quote:
					'No internal database management; emails and phone numbers actually work. We can train someone in 5 minutes — just hit the orange button!',
				name: 'Kyle Bonde',
				title: 'Lead Estimator at',
				company: 'Hensel Phelps',
			},
			{
				image: <NickImage />,
				quote: quotes.nick,
				name: 'Nick Downes',
				title: 'Chief Estimator at',
				company: 'Plaza Construction',
			},
		]}
	/>
)

export default Quotes
