// @flow
import * as React from 'react'
import { A } from 'glamorous'
import { brandBlue } from 'shared/colors'
import { numberOfSubsOnBC } from 'shared/constants'
import { isUSA } from 'shared/get-country'

const FAQLink = ({ children, ...other }) => (
	<A
		color={brandBlue}
		fontWeight="700"
		target="_blank"
		onClick={e => e.stopPropagation()}
		{...other}
	>
		{children}
	</A>
)

const qas = country => {
	const bidOrTender = isUSA(country) ? 'bid' : 'tender'
	const href = isUSA(country)
		? `https://buildingconnected.zendesk.com/hc/en-us/articles/360002853754-How-to-use-the-Suggest-an-Edit-feature`
		: 'https://buildingconnected.zendesk.com/hc/en-ie/articles/360002853754--How-to-use-the-Suggest-an-Edit-feature'
	return [
		{
			question: `Does BuildingConnected share my private subcontractor lists with competitors?`,
			answer:
				'A: Private data, like your preferred subcontractors or project details, is never shared with the competition.',
		},
		{
			question: `How is the contact information kept up to date? `,
			answer: (
				<div>
					A: Contact information is updated with the help of every single
					construction professional in our network. If you see an error or
					outdated contact information, you can suggest an edit to a company or
					user’s profile. The suggested edit updates immediately for your
					company and then once verified, the new information will be public
					across BuildingConnected’s network. Learn more about it{' '}
					<FAQLink href={href}>here</FAQLink>.
				</div>
			),
		},
		{
			question: `What happens to my information when I join the network?`,
			answer: `A: When you join the network, you will immediately get access to over ${numberOfSubsOnBC} subcontractors that are ready to ${bidOrTender}. Your information will remain private from subcontractors until you send out invitations to ${bidOrTender}.`,
		},
	]
}

export default qas
