import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const KyleImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(relativePath: { eq: "network/quotes/img/kyle@3x.png" }) {
					childImageSharp {
						fixed(height: 100, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage fixed={data.fileName.childImageSharp.fixed} alt="Kyle Image" />
	)
}

export default KyleImage
