// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { InfoAndImage, H2, SectionSpacer } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'

import network from './img/network.gif'
import GcLogos from 'components/gc-logos'

const getWordingWidth = screenWidth => {
	if (screenWidth > breakpoints.laptop) {
		return '617px'
	} else if (screenWidth > breakpoints.mobile) {
		return '480px'
	}
	return '266px'
}

const NetworkImage = () => (
	<Div
		display="flex"
		height="100%"
		alignItems="center"
		css={{
			[tablet]: { maxWidth: '70%', margin: '0 auto' },
			[mobile]: { maxWidth: '84%' },
		}}
	>
		<img width="100%" src={network} alt="loading" />
	</Div>
)

const AlwaysUpdating = ({ screenWidth, trust }) => (
	<Div>
		<InfoAndImage
			screenWidth={screenWidth}
			image={<NetworkImage />}
			imageOnRight
			stackedImageOnTop
			spacerWidths={['60px', '10px']}
			subtitle={
				<Div
					css={{
						[laptop]: { fontSize: '38px', maxWidth: '635px' },
						[mobile]: { fontSize: '32px' },
					}}
				>
					A living, breathing network that’s always updating.
				</Div>
			}
			info={
				<Div
					css={{
						[laptop]: { fontSize: '22px', maxWidth: '460px' },
						[mobile]: { fontSize: '20px', maxWidth: '100%' },
					}}
				>
					<Div>
						The BuildingConnected network is collectively maintained by every
						participant in real time, so you’ll never waste time manually
						updating your database again.
					</Div>
					<br />
					<Div>
						Keeping your data safe is our top priority. Project data is
						encrypted and securely stored. Preferred subcontractor lists are
						never shared with your competition.
					</Div>
				</Div>
			}
			centered={screenWidth < breakpoints.laptop}
			wordingWidth={getWordingWidth(screenWidth)}
		/>
		<SectionSpacer />
		<H2
			textAlign="center"
			margin="0 auto"
			additionalCss={{
				[laptop]: { maxWidth: '708px', fontSize: '38px' },
				[tablet]: { maxWidth: '692px' },
				[mobile]: { maxWidth: '294px', fontSize: '32px' },
			}}
		>
			{trust}
		</H2>
		<Div
			height={90}
			css={{
				[laptop]: { height: 54 },
				[mobile]: { height: 32 },
			}}
		/>
		<GcLogos />
	</Div>
)

export default AlwaysUpdating
