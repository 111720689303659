import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { breakpoints } from 'shared/media-queries'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

const Div = styled.div`
	width: 100%;
`

const DiscoverImage = ({ screenWidth }) => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(
					relativePath: { eq: "network/discover/img/discover-anz.png" }
				) {
					childImageSharp {
						fixed(height: 448, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				laptop: file(
					relativePath: { eq: "network/discover/img/discover-anz.png" }
				) {
					childImageSharp {
						fixed(height: 370, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				tablet: file(
					relativePath: { eq: "network/discover/img/discover-anz.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1300) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	if (screenWidth <= breakpoints.tablet) {
		return (
			<Div>
				<GatsbyImage
					fluid={data.tablet.childImageSharp.fluid}
					alt="discover anz"
				/>
			</Div>
		)
	} else if (screenWidth < breakpoints.laptop) {
		return (
			<GatsbyImage
				fixed={data.laptop.childImageSharp.fixed}
				alt="discover anz"
			/>
		)
	}
	return (
		<GatsbyImage
			fixed={data.desktop.childImageSharp.fixed}
			alt="discover anz"
		/>
	)
}

export default DiscoverImage
