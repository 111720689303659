// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { Hero } from 'views/components'
import H1 from 'components/h1'
import { breakpoints, tablet, mobile } from 'shared/media-queries'
import backgroundImage from './background-image'
import { lightBlue } from 'shared/colors'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '600px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '600px'
	}
	return '519px'
}

const heading = content => (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		{content}
	</H1>
)

const subHeading = content => (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		{content}
	</Div>
)

const Header = ({ screenWidth, header, subheader, country }) => (
	<Hero
		backgroundImage={backgroundImage({ country, screenWidth })}
		heading={heading(header)}
		subHeading={subHeading(subheader)}
		wordingWidth={getWordingWidth(screenWidth)}
		ctaColor={lightBlue}
	/>
)

export default Header
