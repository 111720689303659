import { countryCodes } from 'shared/get-country'

import { numberOfSubsOnBCWording } from 'shared/constants'

const usCopy = {
	header: {
		header: 'Get more from the network with BuildingConnected Pro.',
		subheader: `Connect with over ${numberOfSubsOnBCWording} subcontractors on the most up-to-date network.`,
	},
	quotes: {
		nick:
			'BuildingConnected’s network is updated in real time, and bid document distribution has been streamlined to the ease of a few clicks of the mouse.',
	},
	discover: {
		bulletItems: [
			'See where subcontractors are willing to travel',
			'Search by trade',
			'Filter by labor requirements',
			'Easily surface TradeTapp qualified contractors',
		],
	},
	updating: {
		trust: '13 of the ENR top 20 general contractors trust BuildingConnected.',
	},
	supercharge: {
		info:
			'Working with your colleagues has never been easier. With BuildingConnected Pro, you can share contacts, templates, bidder lists and subcontractor notes across your team—and across every office in your company.',
	},
}
const anzCopy = {
	header: {
		header: 'Win more work with a real-time construction network. ',
		subheader: 'Find the right subcontractors and centralise your tendering.',
	},
	quotes: {
		nick:
			'BuildingConnected’s network is updated in real-time, and tender document distribution has been streamlined to the ease of a few clicks of the mouse.',
	},
	discover: {
		bulletItems: [
			'See where subcontractors are willing to travel',
			'Search by trade',
			'Filter by labor requirements',
		],
	},
	updating: {
		trust: '13 of the ENR top 20 Head Contractors trust BuildingConnected.',
	},
	supercharge: {
		info:
			'Working with your colleagues has never been easier. With BuildingConnected Pro, you can share contacts, templates, tenderer lists and subcontractor notes across your team—and across every office in your company.',
	},
}

const ukCopy = {
	...anzCopy,
	updating: {
		trust: '13 of the ENR top 20 Main Contractors trust BuildingConnected.',
	},
}

const copy = {
	[countryCodes.us]: usCopy,
	[countryCodes.au]: anzCopy,
	[countryCodes.nz]: anzCopy,
	[countryCodes.uk]: ukCopy,
	[countryCodes.ie]: ukCopy,
}

const content = country => copy[country]

export default content
