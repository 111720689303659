import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { breakpoints } from 'shared/media-queries'

const BackgroundImage = ({ screenWidth }) => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(
					relativePath: {
						eq: "network/header/background-image/img/desktop-other.jpg"
					}
				) {
					childImageSharp {
						fixed(height: 600, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				laptop: file(
					relativePath: {
						eq: "network/header/background-image/img/laptop-other.png"
					}
				) {
					childImageSharp {
						fixed(height: 500, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				tablet: file(
					relativePath: {
						eq: "network/header/background-image/img/tablet-other.png"
					}
				) {
					childImageSharp {
						fixed(height: 400, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				mobile: file(
					relativePath: {
						eq: "network/header/background-image/img/mobile-other.png"
					}
				) {
					childImageSharp {
						fixed(height: 409, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	if (screenWidth < breakpoints.mobile) {
		return (
			<GatsbyImage
				fixed={data.mobile.childImageSharp.fixed}
				alt="network header int"
			/>
		)
	} else if (screenWidth < breakpoints.tablet) {
		return (
			<GatsbyImage
				fixed={data.tablet.childImageSharp.fixed}
				alt="network header int"
			/>
		)
	} else if (screenWidth < breakpoints.laptop) {
		return (
			<GatsbyImage
				fixed={data.laptop.childImageSharp.fixed}
				alt="network header int"
			/>
		)
	}
	return (
		<GatsbyImage
			fixed={data.desktop.childImageSharp.fixed}
			alt="network header int"
		/>
	)
}

export default BackgroundImage
