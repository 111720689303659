// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import withCountry from 'shared/get-country'
import Layout from 'components/layout'
import {
	SectionSpacer,
	SectionBreak,
	ContentWrapper,
	Footer,
} from 'views/components'
import useScreenWidth from 'hooks/use-screen-width'
import Header from './header'
import SaveTime from './save-time'
import Discover from './discover'
import AlwaysUpdating from './always-updating'
import SuperCharge from './supercharge'
import Quotes from './quotes'
import FAQs from './faq'
import { lightBlue, grayBackground } from 'shared/colors'
import { numberOfSubsOnBCWording } from 'shared/constants'
import generateContent from './content'

const NetworkLP = ({ country }) => {
	const content = generateContent(country)
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}
	if (!country) return null
	const { header, discover, quotes, supercharge, updating } = content

	return (
		<Layout
			title="BuildingConnected Pro Construction Network | BuildingConnected"
			description={`Discover over ${numberOfSubsOnBCWording} top subcontractors using BuildingConnected's real-time, crowdsourced network.`}
		>
			<Header screenWidth={screenWidth} {...header} country={country} />
			<SectionSpacer />
			<ContentWrapper>
				<Discover screenWidth={screenWidth} {...discover} country={country} />
				<SectionBreak />
				<SaveTime screenWidth={screenWidth} country={country} />
				<SectionBreak />
				<AlwaysUpdating screenWidth={screenWidth} {...updating} />
				<SectionBreak />
				<SuperCharge
					screenWidth={screenWidth}
					{...supercharge}
					country={country}
				/>
				<SectionSpacer />
			</ContentWrapper>
			<Div backgroundColor={grayBackground}>
				<SectionSpacer />
				<Quotes {...quotes} />
				<SectionSpacer />
			</Div>
			<SectionSpacer />
			<ContentWrapper>
				<FAQs country={country} />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Unlock the full power of the network with BuildingConnected Pro."
				demoButtonWording="Get a demo"
				demoButtonPadding="55px !important"
				showCreateAccount
				ctaColor={lightBlue}
			/>
		</Layout>
	)
}

export default withCountry(NetworkLP)
