import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const BrianImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "network/quotes/img/brian@3x.png" }
				) {
					childImageSharp {
						fixed(height: 100, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fixed={data.fileName.childImageSharp.fixed}
			alt="Brian Image"
		/>
	)
}

export default BrianImage
