import * as React from 'react'
import { countryCodes } from 'shared/get-country'

import { default as Image } from './us'
import { default as ImageOther } from './other'

const images = ({ country, screenWidth }) => {
	const i = {
		[countryCodes.us]: <Image screenWidth={screenWidth} />,
		[countryCodes.nz]: <ImageOther screenWidth={screenWidth} />,
		[countryCodes.au]: <ImageOther screenWidth={screenWidth} />,
		[countryCodes.uk]: <ImageOther screenWidth={screenWidth} />,
		[countryCodes.ie]: <ImageOther screenWidth={screenWidth} />,
	}
	return i[country]
}

const constants = ({ country, screenWidth }) => images({ country, screenWidth })
export default constants
