// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, mobile } from 'shared/media-queries'

import SaveTimeImage from './img'

const getWordingWidth = screenWidth => {
	if (screenWidth > breakpoints.laptop) {
		return '485px'
	} else if (screenWidth > breakpoints.mobile) {
		return '460px'
	}
	return '460px'
}

const SuperCharge = ({ country, screenWidth, info }) => (
	<Div>
		<InfoAndImage
			screenWidth={screenWidth}
			image={<SaveTimeImage country={country} />}
			stackedImageOnTop
			subtitle={
				<Div
					css={{
						[laptop]: { fontSize: '38px', maxWidth: '460px' },
						[mobile]: { fontSize: '32px' },
					}}
				>
					Supercharge collaboration across all your offices.
				</Div>
			}
			info={
				<Div
					css={{
						[laptop]: { fontSize: '22px', maxWidth: '512px' },
						[mobile]: { fontSize: '20px', maxWidth: '314px' },
					}}
				>
					{info}
				</Div>
			}
			centered={screenWidth < breakpoints.laptop}
			wordingWidth={getWordingWidth(screenWidth)}
		/>
	</Div>
)

export default SuperCharge
